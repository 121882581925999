import { css } from '@emotion/react'
import { I18nContext, localizePath } from 'gatsby-plugin-i18n'
import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import Common from 'swp-components/lib/components/common'

const style = {
  image: css`
    margin: 1rem 0;
    width: 240px;
  `,

  font: css`
    font-family: var(--text-normal);
    font-size: var(--fs-xs);
  `,
}

const NotFoundPage = () => {
  let { locale, defaultLocale } = useContext(I18nContext)

  let link = {
    url: localizePath('/', locale, defaultLocale),
  }

  return (
    <div className="px-3 py-5 text-center">
      <img css={style.image} src="/images/bgPXd.png" alt="page-not-found" />
      <div className="fs-3" css={style.font}>
        <FormattedMessage
          id="NotFoundPage.titleText"
          defaultMessage="Page Not Found"
        />
      </div>
      <div className="mt-3" css={style.font}>
        <FormattedMessage
          id="NotFoundPage.descriptionText"
          defaultMessage="Sorry, the page you requested could not be found. Please check the URL and try again."
        />
      </div>
      <div className="mt-4">
        <Common.Button variant="outlined" link={link}>
          <FormattedMessage
            id="NotFoundPage.homeButtonText"
            defaultMessage="Back to Home"
          />
        </Common.Button>
      </div>
    </div>
  )
}

export default NotFoundPage
